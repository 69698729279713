import styled from "styled-components"

import Hero1024 from "../../images/Hero-1024.png"
import Hero1920 from "../../images/Hero-1920.png"
import HeroMobile from "../../images/Hero-Mobile.png"

import {
  DESKTOP_1366,
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_800,
  MOBILE_460,
  MOBILE_380,
} from "../globals/sizes"

export const HeroContainer = styled.div`
  margin-top: 90px;
  height: 568px;
  background-image: linear-gradient(270deg, #5a00a700 20%, #2f0333e4 100%),
    url(${Hero1920});
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${DESKTOP_1366}px) {
    background-position-x: left;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    background-position-x: center;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    background-image: linear-gradient(270deg, #5a00a700 20%, #2f0333e4 100%),
      url(${Hero1024});
    background-position-x: right;
  }
  @media (max-width: ${TABLET_992}px) {
    background-image: linear-gradient(270deg, #5a00a700 20%, #2f0333ac 100%),
      url(${Hero1024});
    background-position-x: center;
    display: flex;
    justify-content: flex-start;
    text-align: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    background-image: linear-gradient(270deg, #5a00a700 20%, #2f033386 100%),
      url(${HeroMobile});
    height: 926px;
    margin-top: 30px;
    background-position-x: left;
  }
`

export const HeroContent = styled.div`
  @media (max-width: ${TABLET_992}px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const TextDiv = styled.div`
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${TABLET_992}px) {
    margin-top: 120px;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  margin-top: 40px !important;
  color: white;
  font: 600 60px/65px "Nunito";

  @media (max-width: ${DESKTOP_1200}px) {
    font: 600 55px/60px "Nunito";
  }
  @media (max-width: ${TABLET_992}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_800}px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px !important;
    text-align: left;
  }
`

export const LineBreak = styled.br`
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`

export const LifeLogo = styled.img`
  width: 310px;
  height: 130px;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 290px;
    height: 110px;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 260px;
    height: 80px;
    position: relative;
    top: 100px;
  }
  @media (max-width: ${MOBILE_460}px) {
    top: -8px;
  }
  @media (max-width: ${MOBILE_380}px) {
    top: 20px;
  }
`
