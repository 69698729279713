import React, { useEffect } from "react"

import Layout from "../components/layout"
import Seoinfo from "../components/seoinfo/seoinfo"

import Life from "../images/LIFE.svg"

import OurServices from "../components/OurServices/index"
import GetInTouchSection from "../components/getInTouchSection/getInTouchSection"
import AOS from "aos"

import { InternalWrapper } from "../styles/globals/styles.jsx"

import {
  HeroContainer,
  HeroContent,
  LifeLogo,
  TextDiv,
  Title,
  LineBreak,
} from "../styles/pages/home.jsx"

const Home = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  return (
    <Layout hasTransparentTopBar>
      <Seoinfo
        siteName={"Life Health Group"}
        titlePage={"Promoting Quality of Life"}
        description={
          "Life Health Group provides 360 services promoting quality of life. From our Home Care Services to our Ride Services and our Durable Medical Equipment Program."
        }
        keywords={
          "home care, home therapy, durable medical equipment, ride, senior, care services, home, health, nursing, hospice"
        }
        type={"website"}
        url={"homecare.life"}
        imageFacebook={"/seo/seo_image.png"}
      />
      <HeroContainer alt="Women and men giving high five to each other">
        <InternalWrapper data-aos="fade">
          <HeroContent>
            <LifeLogo src={Life} />
            <TextDiv>
              <Title>
                Promoting quality of life, <LineBreak />
                providing compassionate care
              </Title>
            </TextDiv>
          </HeroContent>
        </InternalWrapper>
      </HeroContainer>
      <OurServices />
      <GetInTouchSection />
    </Layout>
  )
}

export default Home
