import React, { useEffect } from "react"
import AOS from "aos"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import Phone from "../../images/phone-call.svg"

import {
  GetInTouchContainer,
  GetInTouchext,
  PhoneIcon,
  GetInTouchButton,
  GetInTouchDiv,
  SmallText,
  FirstSection,
} from "./styles.jsx"

const GetInTouchSection = ({ maxWidth }) => {
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  return (
    <GetInTouchContainer>
      <a id="contact_us"></a>
      <div data-aos="fade" className="container">
        <GetInTouchDiv>
          <FirstSection>
            <PhoneIcon src={Phone} />
            <GetInTouchext>
              To find out more about our services
              <SmallText>Please don’t hesitate to contact us</SmallText>
            </GetInTouchext>
          </FirstSection>
          <a href="tel: 9732733441">
            <GetInTouchButton >
              <FontAwesomeIcon icon={faPhone} size="1x" /> {" "}
            </GetInTouchButton>
          </a>
        </GetInTouchDiv>
      </div>
    </GetInTouchContainer>
  )
}

export default GetInTouchSection
