import styled from "styled-components"

import { TABLET_992, TABLET_768, MOBILE_460 } from "../../styles/globals/sizes"

import { RED_TITLE, WHITE, GRAY_HOVER } from "../../styles/globals/colors"

export const PhoneIcon = styled.img`
  align-self: start;
  margin: 6px 25px 0;
  @media (max-width: ${TABLET_992}px) {
    margin: 0;
    padding-right: 20px;
    align-self: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 0;
    padding: 0 20px 20px;
    align-self: center;
  }
`

export const GetInTouchContainer = styled.div`
  background-color: #ec4746;
  display: flex;
  flex-direction: row;
  padding: 70px 0;
  background-size: cover;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
`

export const GetInTouchext = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${WHITE};
  font-weight: 600;
  display: flex;
  flex-direction: column;

  @media (max-width: ${TABLET_768}px) {
    text-align: center;
    font-size: 20px;
  }
`

export const GetInTouchDiv = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1024px
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const FirstSection = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    flex-direction: column;
  }
`

export const GetInTouchButton = styled.button`
  margin: 0 20px;
  padding: 14px 40px;
  font-size: 16px;
  font-weight: 600;
  background-color: ${WHITE};
  color: ${RED_TITLE};
  border-radius: 25px;
  opacity: 1;
  border: 0;
  transition: all 0.5s ease;
  :after {
    content: "CONTACT US";
  }
  :hover,
  :hover:after {
    color: ${GRAY_HOVER};
    content: "(973) 273-3441";
  }

  @media (max-width: ${TABLET_992}px) {
    width: fit-content;
    margin-top: 20px;
  }
`

export const BoldText = styled.div`
  font-weight: 600;
  display: contents;
`

export const SmallText = styled.div`
  margin-top: 15px;
  font-size: 20px;
  line-height: 30px;
  color: ${WHITE};
  font-weight: 300;
`
