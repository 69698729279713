import React from "react"

import { SERVICES } from "../../config/urls"

import TheraphyPink from "../../images/homeTheraphyPink.svg"
import HomeCarePink from "../../images/homeCarePink.svg"
import DmePink from "../../images/dmePink.svg"
import RidePink from "../../images/ridePink.svg"
import Hospice from "../../images/logo_hospice-01.svg"


import TheraphyGrayBackground from "../../images/lifeTherapyGrayScale.png"
import DurableGrayBackground from "../../images/lifeDMEGrayScale.png"
import HomeCareGrayBackground from "../../images/lifeHomecareGrayScale.png"
import RideGrayBackground from "../../images/lifeRideGrayScale.png"
import TheraphyBackground from "../../images/HOMETHERAPYBackground.png"
import DurableBackground from "../../images/DURABLEMEDICALBackground.png"
import CareBackground from "../../images/LIFEHOMECAREBackground.png"
import RideBackground from "../../images/LIFERIDEBackground.png"
import hospiceBackground from "../../images/hospiceBackground.png"
import HospiceGreyBackground from "../../images/hospice-logo.png"
import whitearrow from "../../images/white-arrow.svg"

import {
  CardsSection,
  Card,
  LogoLife,
  CardsContainer,
  CardTextContainer,
  CardTitle,
  CardText,
  ExternalLink,
  CardsSectionMobile,
  MobileCard,
  RedirectMobile,
} from "./styles"

const OurServices = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <CardsContainer>
      <a id="our_services" />
      <CardsSection>
        <ExternalLink href={SERVICES.HOME_THERAPY} target="_blank">
          <Card
            grayImage={TheraphyGrayBackground}
            image={TheraphyBackground}
            leftSide
          >
            <LogoLife src={TheraphyPink} />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>
                LIFE HOME <br /> THERAPY
              </CardTitle>
              <CardText>
                We seek to elevate your day-to-day by providing the assistance
                and support you need empowering your quality of life.
              </CardText>
            </CardTextContainer>
          </Card>
        </ExternalLink>
        <ExternalLink href={SERVICES.DME} target="_blank">
          <Card grayImage={DurableGrayBackground} image={DurableBackground}>
            <LogoLife src={DmePink} />
            <CardTextContainer className="CardTextContainer">
              <CardTitle lessSize>
                DURABLE MEDICAL <br /> EQUIPMENT
              </CardTitle>
              <CardText>
                Our DME program is designed with a focus on the health and
                safety of our clients and is delivered with a passion and
                commitment to excellence.
              </CardText>
            </CardTextContainer>
          </Card>
        </ExternalLink>
        <ExternalLink href={SERVICES.HOME_CARE} target="_blank">
          <Card
            grayImage={HomeCareGrayBackground}
            image={CareBackground}
            leftSide
          >
            <LogoLife src={HomeCarePink} />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>
                LIFE HOME <br /> CARE
              </CardTitle>
              <CardText>
                We provide compassionate care for individuals requiring
                assistance with one or more of their activities of daily living
                such as bathing, dressing, and ambulation. All in the comfort of
                their homes.
              </CardText>
            </CardTextContainer>
          </Card>
        </ExternalLink>
        <ExternalLink href={SERVICES.RIDE} target="_blank">
          <Card
            grayImage={RideGrayBackground}
            image={RideBackground}
            className="TranslateRight"
          >
            <LogoLife src={RidePink} />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>LIFE RIDE</CardTitle>
              <CardText>
                Wheelchair accessible rides on demand. 100% Secure door-to-door
                transportation and care.
              </CardText>
            </CardTextContainer>
          </Card>
        </ExternalLink>
        <ExternalLink href={SERVICES.HOSPICE} target="_blank">
          <Card
            grayImage={HospiceGreyBackground}
            image={hospiceBackground}
            leftSide
          >
            <LogoLife src={Hospice} />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>LIFE HOSPICE</CardTitle>
              <CardText>
                Our mission is to offer compassionate and high-quality care,
                meeting the medical, spiritual, and emotional needs of patients
                and families during their end-of-life journey.
              </CardText>
            </CardTextContainer>
          </Card>
        </ExternalLink>
      </CardsSection>
      <CardsSectionMobile>
        <MobileCard onClick={() => setOpen(!open)}>
          <Card grayImage={TheraphyGrayBackground} image={TheraphyBackground}>
            <LogoLife src={TheraphyPink} biggerLogo alt="home theraphy logo" />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>
                LIFE HOME <br /> THERAPY
              </CardTitle>
              <CardText>
                We seek to elevate your day-to-day by providing the assistance
                and support you need empowering your quality of life.
              </CardText>
              <RedirectMobile
                href={SERVICES.HOME_THERAPY}
                bgImage={whitearrow}
                target="_blank"
                rel="noreferrer"
              />
            </CardTextContainer>
          </Card>
        </MobileCard>
        <MobileCard onClick={() => setOpen(!open)}>
          <Card grayImage={DurableGrayBackground} image={DurableBackground}>
            <LogoLife
              src={DmePink}
              lessSize
              alt="durable medical equipment logo"
            />
            <CardTextContainer className="CardTextContainer">
              <CardTitle lessSize>
                DURABLE MEDICAL <br /> EQUIPMENT
              </CardTitle>
              <CardText>
                Our DME program is designed with a focus on the health and
                safety of our clients and is delivered with a passion and
                commitment to excellence.
              </CardText>
              <RedirectMobile
                href={SERVICES.DME}
                bgImage={whitearrow}
                target="_blank"
                rel="noreferrer"
              />
            </CardTextContainer>
          </Card>
        </MobileCard>
        <MobileCard onClick={() => setOpen(!open)}>
          <Card grayImage={HomeCareGrayBackground} image={CareBackground}>
            <LogoLife src={HomeCarePink} alt="Home Care logo" />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>
                LIFE HOME <br /> CARE
              </CardTitle>
              <CardText>
                We provide compassionate care for individuals requiring
                assistance with one or more of their activities of daily living
                such as bathing, dressing, and ambulation. All in the comfort of
                their homes.
              </CardText>
              <RedirectMobile
                href={SERVICES.HOME_CARE}
                bgImage={whitearrow}
                target="_blank"
                rel="noreferrer"
              />
            </CardTextContainer>
          </Card>
        </MobileCard>
        <MobileCard onClick={() => setOpen(!open)}>
          <Card grayImage={RideGrayBackground} image={RideBackground}>
            <LogoLife src={RidePink} lessSize alt="ride logo" />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>LIFE RIDE</CardTitle>
              <CardText>
                Wheelchair accessible rides on demand. 100% Secure door-to-door
                transportation and care.
              </CardText>
              <RedirectMobile
                href={SERVICES.RIDE}
                bgImage={whitearrow}
                target="_blank"
                rel="noreferrer"
              />
            </CardTextContainer>
          </Card>
        </MobileCard>
        <MobileCard onClick={() => setOpen(!open)}>
          <Card grayImage={HospiceGreyBackground} image={hospiceBackground}>
            <LogoLife src={Hospice} lessSize alt="hospice logo" />
            <CardTextContainer className="CardTextContainer">
              <CardTitle>LIFE HOSPICE</CardTitle>
              <CardText>
                Our mission is to offer compassionate and high-quality care,
                meeting the medical, spiritual, and emotional needs of patients
                and families during their end-of-life journey.
              </CardText>
              <RedirectMobile
                href={SERVICES.HOSPICE}
                bgImage={whitearrow}
                target="_blank"
                rel="noreferrer"
              />
            </CardTextContainer>
          </Card>
        </MobileCard>
      </CardsSectionMobile>
    </CardsContainer>
  )
}

export default OurServices
