import styled from "styled-components"

import { TABLET_992, DESKTOP_1366, MOBILE_460 } from "../../styles/globals/sizes"

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px auto;
  @media (max-width: ${TABLET_992}px) {
    margin: 50px auto;
  }
`

export const CardsSection = styled.div`
  width: 65%;
  align-items: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  
  @media (max-width: ${DESKTOP_1366}px) {
    width: 85%;
  }

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`

export const Card = styled.div`
  height: 262px;
  width: 394px;
  flex: 1;
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
  background-image: url(${props => props.grayImage});
  justify-content: center;
  border-radius: 25px;
  padding: 89px 0;
  transition: none;
  margin-bottom: 30px;

  :hover,
  :active {
    padding: 0;
    background-size: cover;
    background-image: url(${props => props.image});
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease;
    @media (min-width: 992px) {
      transform: ${props =>
        props.leftSide
          ? "translate(-20px, 0px) scale(1.1)"
          : "translate(20px, 0px) scale(1.1)"};
    }
    img {
      display: none;
    }
    .CardTextContainer {
      display: flex;
    }
  }

  @media (max-width: ${TABLET_992}px) {
    transition: none;
    margin: 10px auto;
    flex: 1;
    display: flex;
    padding: 20px 40px;
    :active,
    :hover {
      transform: scale(1);
      transition: all 0.2s ease;
      img {
        display: none;
      }
      .CardTextContainer {
        display: flex;
      }
    }
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 300px;
  }
`

export const LogoLife = styled.img`
  display: flex;
  @media (max-width: ${TABLET_992}px) {
    width: ${props => (props.lessSize ? `200px` : `240px`)};
    width: ${props => props.biggerLogo && `270px`};
  }
  @media (max-width: ${MOBILE_460}px) {
    width: ${props => (props.lessSize ? `170px` : `210px`)};
    width: ${props => props.biggerLogo && `235px`};
  }
`

export const CardTextContainer = styled.div`
  width: 100%;
  padding: 40px 35px 0 35px;
  flex-direction: column;
  display: none;

  @media (max-width: ${TABLET_992}px) {
    padding-top: 30px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-top: 25px;
  }
`

export const CardTitle = styled.div`
  font: ${props =>
    props.lessSize ? `800 30px/34px "Nunito"` : `800 38px/38px "Nunito"`};
  color: white;
`

export const CardText = styled.div`
  margin-top: 15px;
  color: white;
  font: 400 16px/20px "Nunito";
`

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const CardsSectionMobile = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
  }
`

export const MobileCard = styled.div``

export const RedirectMobile = styled.a`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: ${props =>
    props.bgImage ? `url(${props.bgImage}) center/cover no-repeat;` : ``};
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: block;
  }
`
